<template>
    <div class="container">
        <div class="imageContainer">
            <div class="imageHero">
                <img src="../assets/profile.jpg" alt="Javier Photo">
            </div>
        </div>
        <div class="contactButton">
                <h1>Javier Wensko <br> Web Developer</h1>
                <ButtonComponent text="Contact Me" type="primary" icon="false" link="mailto:contact@javierwnk.com.ar" />
            </div>
        <div class="information">
            
            <h2>Hey, I'm <br> Javier Wensko</h2>

            <h3>I am a self-motivated and hardworking <span> Front End Developer </span> with extensive experience in the ecommerce industry. I
            work effectively both within a team and individually using my own initiative. I believe that I will be a valuable
            asset to any organisation giving my experience, dedication and the great pride that I take in my work. </h3>

            <div class="socialNetwork">
                <ButtonComponent text="" type="icon" icon="github" link="https://github.com/javierwnk" />
                <ButtonComponent text="" type="icon" icon="linkedin" link="https://www.linkedin.com/in/javierwensko/" />
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from './ButtonComponent.vue';
export default {
    components: { ButtonComponent,  }
}
</script>
<style scoped>

.container {
    display: grid;
    grid-template-areas: 'image contact'
    'image info';
    column-gap: 34px;
    width: 90%;
    margin: 24px auto;
    justify-content: space-evenly;
}

.imageContainer {
    min-height: 480px;
    width: 380px;
    background-color: hsla(0, 0%, 100%, 0.04);
    border-radius: 16px;
    margin: 24px 0 0 24px;
    border: 1px solid hsla(0, 0%, 100%, 0.04);

    grid-area: image;
}

.imageHero {
    width: 380px;
    height: 480px;
    margin-top: -24px;
    margin-left: -24px;
    border-radius: 16px;
    
}

img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: top;
    border-radius: 16px;
}

.information {
    width: 590px;
    display: flex;
    flex-flow: column nowrap;

    grid-area: info;
}

.contactButton {
    display: flex;
    flex-direction: row-reverse;

    grid-area: contact;
}

h1 {
    display: none;
}

h2 {
    color: white;
    font-size: 60px;
}

h3 {
    color: hsla(0, 0%, 100%, 0.8);
    font-size: 18px;
    line-height: 180%;
    margin: 24px 0;
}

span {
    color: #fada38;
}

.socialNetwork {
    display: flex;
}

@media all and (max-width: 1024px) {

.container {
    grid-template-areas: 'image contact'
    'image info';
    width: 90%;
    column-gap: 48px;

}

.imageContainer {
    min-height: 490px;
    width: 100%;

}

.imageHero {
    width: 100%;
    height: 490px;

}


.information {
    width: 80%;
    display: flex;
    flex-flow: column nowrap;

    grid-area: info;
}


h2 {
    font-size: 40px;
}

h3 {
    font-size: 16px;
    line-height: 140%;
}


} /* end media */


@media all and (max-width: 768px) {

.container {
    grid-template-areas: 'image contact'
    'image info';
    width: 90%;
    column-gap: 48px;

}

.imageContainer {
    min-height: 320px;
    width: 100%;

}

.imageHero {
    width: 100%;
    height: 445px;

}


.information {
    width: 80%;
    display: flex;
    flex-flow: column nowrap;

    grid-area: info;
}


h2 {
    font-size: 40px;
}

h3 {
    font-size: 16px;
    line-height: 140%;
}


} /* end media */


@media all and (max-width: 425px) {

.container {
    grid-template-areas: 'contact'
    'image'
    'info';
}

.imageContainer {
    width: 90%;
    margin: 24px auto;
}

.imageHero {
    width: 100%;
    
}

.information {
    width: 90%;
}

.contactButton {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 24px 0;
}

h1 {
    display: block;
    color: white;
    font-size: 16px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 16px;
    line-height: 140%;
    margin: 20px 0;
}

span {
    color: #fada38;
}

.socialNetwork {
    display: flex;
}

} /* end media */


</style>