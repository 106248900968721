<template>
    <div v-if="data.id % 2 == 1" class="project">
        <div class="images">
            <img :src="data.image" :alt="data.title">
        </div>
        <div class="information">
            <div class="category">
                <p>{{ data.category }}</p>
            </div>

            <div class="title">
                <h2>{{ data.title }}</h2>
            </div>

            <div class="description">
                <p>{{ data.description }}</p>
            </div>

            <ul>
                <li v-if="data.website != null">
                        <ButtonComponent text="View site" type="secondary" icon="link45deg" :link="data.website" />
                    </li>
                <li v-if="data.github != null">
                        <ButtonComponent text="View in GitHub" type="secondary" icon="github" :link="data.github" />
                    </li>
                <li v-if="data.figma != null">
                        <ButtonComponent text="View in Figma" type="secondary" icon="figma" :link="data.figma" />
                   </li>
            </ul>
        </div>
    </div>

    <div v-else class="project reverse">
        <div class="images">
            <img :src="data.image" :alt="data.title">
        </div>
        <div class="information">
            <div class="category">
                <p>{{ data.category }}</p>
            </div>

            <div class="title">
                <h2>{{ data.title }}</h2>
            </div>

            <div class="description">
                <p>{{ data.description }}</p>
            </div>

            <ul>
                <li v-if="data.website != null">
                        <ButtonComponent text="View site" type="secondary" icon="link45deg" :link="data.website" />
                    </li>
                <li v-if="data.github != null">
                        <ButtonComponent text="View in GitHub" type="secondary" icon="github" :link="data.github" />
                    </li>
                <li v-if="data.figma != null">
                        <ButtonComponent text="View in Figma" type="secondary" icon="figma" :link="data.figma" />
                   </li>


            </ul>
        </div>
    </div>

</template>
<script>
import ButtonComponent from './ButtonComponent.vue';
export default {
    name: "ProjectComponent",
    props: {
        data: Object
    },
    components: { ButtonComponent }
}
</script>
<style scoped>
.project {
    height: 400px;
    width: 90%;
    margin: 24px auto;

    background-color: hsla(0, 0%, 100%, 0.04);
    border-radius: 16px;
    border: 1px solid hsla(0, 0%, 100%, 0.08);

    display: grid;
    grid-template-areas: 'image text';
    column-gap: 34px;
}

.reverse {
    grid-template-areas: 'text image';
}


.images img {
    width: 500px;
    height: 400px;
    box-shadow: 0 0 64px 0 rgb(0 0 0 / 20%);
    object-fit: cover;
    object-position: top;
    padding: 24px 24px 3px 24px;

    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.images {
    grid-area: image;
}

.information {
    color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    margin-left: 24px;

    grid-area: text;

}

.category {

    width: fit-content;
    margin-bottom: 24px;
    padding-top: 1px;
    padding-right: 14px;
    padding-left: 14px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.08);
    border-radius: 15px;
    font-size: 10px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.title,
.description {
    margin: -24px 0 0 0;
}

h2 {
    width: 60%;
    font-size: 32px;
}

.description p {
    color: hsla(0, 0%, 100%, 0.8);
    font-size: 16px;
}

ul {
    list-style-type: none;
}

@media all and (max-width: 768px) {

.project {
    height: fit-content;
    grid-template-areas: 'image'
    'text';
    row-gap: 34px;
    column-gap: 0px;
    padding: 0 0 12px 0;
}

.reverse {
    grid-template-areas: 'image'
    'text';
}


.images img {
    width: 100%;
}


.title,
.description {
    margin: 0;
}

.title {
    margin: -12px 0 0 0;
}

.description {
    padding: 0 24px 12px 0;
}

h2 {
    width: 100%;
}

.description p {
    font-size: 14px;
}


} /* end media */

</style>