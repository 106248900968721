<template>
  <div>
    <PresentationComponent />
    <ProjectComponent v-for="project in projects" :key="project.index" :data="project"/>
    <FooterComponent />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import ProjectComponent from './components/ProjectComponent.vue'
import PresentationComponent from './components/PresentationComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
    name: "App",
    computed: {
        ...mapState([
            "media",
            "projects"
        ])
    },
    methods: {
        ...mapActions([
            "getMedia",
            "getProjects"
        ])
    },
    beforeMount() {
        this.getMedia();
        this.getProjects();
    },
    components: { PresentationComponent, ProjectComponent, FooterComponent }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  background: #101319;
}
</style>
