import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: 'https://62e9c20201787ec7121c07b8.mockapi.io/api/',
    media: [],
    projects: []
  },
  getters: {
  },
  mutations: {
    pasteProjects(state, projects) {
      state.projects = projects
    },
    pasteMedia(state, media) {
      state.media = media
    }
  },
  actions: {
    async getProjects ({commit}) {
      const response = await fetch(this.state.url + 'projects')
      const projects = await response.json()

      commit('pasteProjects', projects)
    },
    async getMedia ({commit}) {
      const response = await fetch(this.state.url + 'media')
      const media = await response.json()

      commit('pasteMedia', media)
    },
  },
  modules: {
  }
})
