<template>
    <footer>
        <p>© 2022 Javier Wensko</p>
    </footer>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    footer {
        margin-top: 48px;
        height: 48px;
        text-align: center;
        color: white;
        font-size: 12px;
        font-weight: 700;
    }
</style>